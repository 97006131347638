<template>
  <div class="img-box" style="width: 100%; height: 100%">
    <img ref="imgdom" :src="img" alt="" />
    <div class="footer-box">
      <div @click="cancel">{{ $fanyi("取消") }}</div>
      <div @click="confim">{{ $fanyi("确认") }}</div>
    </div>
  </div>
</template>
<script setup>
import dayjs from "dayjs";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import { getCurrentInstance, nextTick, onMounted, ref, watch } from "vue";
import { Toast } from "vant";

const { proxy } = getCurrentInstance();
const imgdom = ref("");
const cropper = ref(null);
const props = defineProps({
  img: {
    type: [String, Number],
    require: true,
  },
});
onMounted(() => {
  getcropper();
});
const getcropper = () => {
  // 获取图片元素
  const image = imgdom.value;
  cropper.value = new Cropper(image, {
    viewMode: 0.5,
    dragMode: "move",
    aspectRatio: 1,
    cropBoxMovable: false,
    cropBoxResizable: false,
    background: false,
    movable: true,
  });
};

// watch(() => props.img, (newvalue, oldvalue) => {
//   console.log(newvalue, oldvalue);
//   nextTick(() => {
//     console.log(imgdom.value.src);
//     imgdom.value.src = newvalue
//     getcropper()
//   })
// }, {
//   immediate: true,
// })
const emit = defineEmits(["cancel", "confim"]);
const cancel = () => {
  console.log(props.value);

  emit("cancel");
};
const confim = () => {
  cropper.value.getCroppedCanvas().toBlob((blob) => {
    // 将blob格式文件转为file
    let files = new window.File([blob], `qwert.${blob.type.split("/")[1]}`, {
      type: blob.type,
    });
    // 将file格式文件转为canvas
    const reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = function (e) {
      // console.log(e);
      // 创建图片元素
      let img = document.createElement("img");
      img.src = e ? e.target.result : "";
      img.onload = () => {
        let { width, height } = img;
        // 计算宽高比 ...
        // 创建canvas画布
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        //压缩尺寸 为方便这里直接/2
        // console.log(width, height);
        // 大小是由宽度决定的 300*300差不多是100到200kb 这里头像被裁剪后一定是正正方形所以写固定值,如果可以上传任意图片的时候不能使用固定值
        width = Math.min(300, width);
        height = Math.min(300, height);
        canvas.width = width;
        canvas.height = height;
        context?.clearRect(0, 0, width, height);
        context?.drawImage(img, 0, 0, width, height);
        // 转成blob
        canvas.toBlob(
          (blob) => {
            // console.log("size", blob?.size);
            emit("confim", blob);
          },
          files.type,
          0.5 //图片的质量
        );
        // 删除创建的图片元素
        img.remove();
      };
    };
  });
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.img-box {
  background-color: #020201;
}

img {
  display: block;
  max-width: 100%;
}

.footer-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  align-items: center;
  font-size: 38px;
  padding: 0 10px;
  height: 80px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}
</style>
