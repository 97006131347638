<!-- 个人信息页面  -->
<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon
        name="arrow-left"
        @click="$fun.routerToPage('/user/userDetail')"
      />
    </template>
    <template #default>
      <span>
        {{ $fanyi("个人信息") }}
      </span>
    </template>
    <!--个人信息区域 -->
  </headBackBar>

  <!-- 内容区域 -->
  <div class="Content">
    <!-- 顶部用户头像区域 -->
    <div class="top-userpic">
      <van-image
        lazy-load
        class="userPicture"
        :src="$store.state.userInfo.portrait_url"
      >
        <template v-slot:loading>
          <img :src="require('@/assets/homePage/defaultUserHead.png')" alt="" />
        </template>
      </van-image>
      <div class="imgbox" @click="clickUpload">
        <img src="../../../../assets/user-img/usersetting/相机.png" alt="" />

        <p class="text">
          {{ $fanyi("替换头像") }}
        </p>
        <input
          style="display: none"
          type="file"
          id="UserFileInput"
          @change="uploadFile()"
        />
      </div>
      <van-popup v-model:show="show" style="width: 100%; height: 100%">
        <Uploadphoto v-if="show" :img="img" @cancel="cancel" @confim="confim" />
      </van-popup>
    </div>
    <!-- 用户登录信息区域 -->
    <div class="Userinformation">
      <div class="item border">
        <div class="left">{{ $fanyi("真名") }}</div>
        <div class="right">{{ $store.state.userInfo.realname }}</div>
      </div>
      <div class="item border">
        <div class="left">{{ $fanyi("登录名") }}</div>
        <div class="right">{{ $store.state.userInfo.username }}</div>
      </div>
      <div class="item border">
        <div class="left">{{ $fanyi("邮箱") }}</div>
        <div class="right">{{ $store.state.userInfo.email }}</div>
      </div>
      <div class="item">
        <div class="left">{{ $fanyi("手机号") }}</div>
        <div class="right">{{ $store.state.userInfo.mobile }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import dayjs from "dayjs";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import Uploadphoto from "../../components/uploadphoto.vue";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();
console.log(proxy.$store.state);
const img = ref(""); // 获取的上传文件的内存地址
const show = ref(false); // 控制弹出框显示隐藏
const clickUpload = () => {
  document.querySelector("#UserFileInput").click();
};
const uploadFile = () => {
  var input = document.getElementById("UserFileInput");
  console.log(input.value);
  var file = input.files[0];
  // 获取上传图片的地址
  const imgurl = window.URL.createObjectURL(file);
  console.log(img.value);
  img.value = imgurl;
  show.value = true;
  input.value = "";
};
// 关闭弹窗
const cancel = () => {
  document.getElementById("UserFileInput").value = "";
  show.value = false;
};
const confim = (file) => {
  var url = "https://jpapi.rakumart.cn/api/common/uploadFile";
  const isJPG =
    ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
  Toast.loading({
    duration: 0,
    message: proxy.$fanyi("保存中..."),
    forbidClick: true,
    loadingType: "spinner",
  });
  if (!isJPG) {
    this.$message.error(proxy.$fanyi("请上传图片"));
    Toast.clear();
    return isJPG;
  }
  var formData = new FormData();
  formData.append("file", file);
  var xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.onload = () => {
    if (xhr.status === 200) {
      const res = JSON.parse(xhr.responseText);
      if (res.code != 0) return Toast(proxy.$fanyi(res.message));
      proxy.$api
        .userPortraitSave({
          portrait_url: res.data,
        })
        .then((data) => {
          if (res.code != 0) {
            Toast.clear();
            Toast(proxy.$fanyi(res.msg));
            return;
          }
          proxy.$api.EuropegetUserInfo().then((res) => {
            proxy.$store.commit("userData", res.data);
            // proxy.$store.commit("userInfo", res.data);
            proxy.$store.commit("changeshowLoginDia", false);
            Toast.clear();
            Toast.success(proxy.$fanyi("保存成功"));
            show.value = false;
          });
        });
    } else {
    }
  };
  xhr.send(formData);
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

.Content {
  width: 750px;
  height: 1400px;
  background: #f6f6f6;
  background: #f6f6f6;
  padding: 0 30px;
  padding-top: 60px;

  .userPicture {
    width: 240px;
    height: 240px;
    border-radius: 50%;
  }

  .top-userpic {
    position: relative;
    margin: auto;
    width: 240px;
    height: 240px;

    border-radius: 50%;
    margin-bottom: 60px;
    background: #ffdbd8;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgbox {
      position: absolute;
      left: 0;
      top: 0;
      width: 240px;
      height: 240px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 50%;
      margin-bottom: 60px;
      display: flex;

      justify-content: center;
      align-items: center;

      img {
        width: 60px;
        height: 52px;
        margin-bottom: 20px;
      }

      .text {
        font-size: 28px;

        font-weight: 400;
        color: #ffffff;
      }

      // &:hover {
      //   display: block;
      // }
    }
  }

  :deep().van-image__img {
    border-radius: 50%;
  }

  .Userinformation {
    width: 690px;
    height: 403px;
    background: #ffffff;
    border-radius: 6px;
    padding: 0 28px;

    .item {
      height: 99px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 2px;

      .left {
        font-size: 28px;

        font-weight: 400;
        color: #000000;
      }

      .right {
        font-size: 28px;

        font-weight: 400;
        color: #999999;
      }
    }

    .border {
      border-bottom: 1px solid #dfdfdf;
    }
  }
}
</style>
